@charset "UTF-8";
/* @import url(../font/iconfont.css); */
html {
  background: #fff; }

body {
  font-size: 14px;
  font-family: -apple-system,  BlinkMacSystemFont,  "Segoe UI",  "Roboto",  "Oxygen",  "Ubuntu",  "Cantarell",  "Fira Sans",  "Droid Sans",  "Helvetica Neue",  sans-serif;
  overflow-x: hidden; }

select {
  -webkit-appearance: none;
  background: #fff;
  outline: none; }

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

*:not(input) {
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -khtml-user-select: none;
  /*早期浏览器*/
  -moz-user-select: none;
  /*火狐*/
  -ms-user-select: none;
  /*IE10*/
  user-select: none; }

[v-cloak] {
  display: none !important;
  /* color: green;*/ }

body, ul, dl, ol
, h1, h2, h3, h4, h5, h6,
p, b {
  padding: 0;
  margin: 0;
  border: 0;
  font-weight: 400; }

button, input {
  padding: 0;
  color: inherit;
  outline: none;
  border: none;
  background: none; }

select {
  border: none; }

textarea {
  padding: 0;
  color: inherit;
  outline: none;
  border: none;
  background: none;
  -webkit-appearance: none; }

.none {
  display: none; }

a {
  color: inherit;
  text-decoration: none; }

ul li, ol li {
  list-style-type: none; }

img {
  display: block;
  width: 100%; }

em, i {
  font-style: normal; }

.shade {
  position: fixed;
  top: 0;
  z-index: 98;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); }

.err {
  position: fixed;
  bottom: 30px;
  min-width: 100px;
  text-align: center;
  color: #fff;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 2px;
  z-index: 99; }
